class TeaserContainer {
    constructor(baseUseClass) {
        this.baseUseClass = baseUseClass;
        this.init = this.init.bind(this);
        this.process = this.process.bind(this);
        this.selector = '.magat.cmp-teasercontainer';
        this.activeFilters = [];
        this.teaserHtml = '';
    }

    init() {
        this.process($(this.selector));
        $(document).customOn('editable-updated', () => {
            this.process($(this.selector));
        });
        $(window).customOn('resizeend', () => {
            this.process($(this.selector));
        });
        $(document).customOn('refresh refreshTeaser', (event, elem) => {
            if (!elem) {
                elem = document.body;
            }
            this.process($(elem).find(this.selector));
        });
        this.initializeFilterFunctionality($(this.selector));
    }

    process(elements) {
        const self = this;
        elements.each((index, element) => {
            let ul = $(element).find('.cmp-teasercontainer__unordered-list');
            let li = ul.children();
            let teasers = self.getVisibleTeasers(li);

            const viewportWidth = window.innerWidth;
            let carousel = $(element).find('.owl-carousel');
            if (!carousel.length && li.length > 1 && ((viewportWidth < 960 && viewportWidth > 640 && !ul.data().carouselDisabledOnTablet)
                || (viewportWidth <= 640 && !ul.data().carouselDisabled))) {
                carousel = ul.addClass('owl-carousel owl-height-greatest owl-theme');
                const waitForOwl = window.setInterval(() => {
                    if (typeof $.fn.owlCarousel === 'function') {
                        window.clearInterval(waitForOwl);
                        carousel.owlCarousel({
                            autoWidth: $(ul).find('.cmp-magentamomentsteaser').length,
                            responsive: {
                                0: {
                                    items: 1.2,
                                    center: true,
                                    dots: true,
                                    margin: 15,
                                },
                                500: {
                                    items: 2,
                                    center: true,
                                    dots: true,
                                    margin: 15,
                                },
                                960: {
                                    items: 4,
                                    mouseDrag: false,
                                    touchDrag: false,
                                    margin: 30,
                                },
                            },
                        });
                    }
                }, 10);
            } else if (carousel.length && ((viewportWidth >= 960
                    || (viewportWidth < 960 && viewportWidth > 640 && ul.data().carouselDisabledOnTablet))
                || (viewportWidth <= 640 && ul.data().carouselDisabled))) {
                carousel.trigger('destroy.owl.carousel');
                carousel.removeClass('owl-carousel owl-theme');
                ul = $(element).find('.cmp-teasercontainer__unordered-list');
                li = ul.children();
                teasers = self.getVisibleTeasers(li);
            }

            const blogTeasers = $(element).find('.cmp-blogteaser');
            const teaserContainerWidth = $(element).innerWidth();
            const columns = ul.data().teaserColumns;
            const columnWidth = Math.round((100 / columns) * 1000) / 1000;
            const flexibleWidth = ul.hasClass('cmp-teasercontainer--flex-width');

            $(teasers).each((i, el) => {
                if (blogTeasers.length && teaserContainerWidth < 960) {
                    $(blogTeasers).each((i_, el_) => {
                        $(el_).find('.cmp_teaser__image').css('padding-bottom', '46.5%');
                    });
                }
                if (viewportWidth < 960) {
                    $(el).css('width', '');
                    /** Remove medium margin */
                    if (!ul.data().carouselDisabled && !ul.data().carouselDisabledOnTablet) {
                        $(el).removeClass('mt-m');
                    }
                    if (i > 0 && ((ul.data().carouselDisabled && viewportWidth <= 640)
                        || (ul.data().carouselDisabledOnTablet && viewportWidth > 640 && viewportWidth < 960))
                        && !ul.attr('class').includes('--compact')
                        && !ul.attr('class').includes('--flex-width')) {
                        if ($(el).closest('.cmp-teasercontainer__two-columns-mobile').length === 0) {
                            $(el).addClass('mt-m');
                        }
                    }
                }
                if (viewportWidth >= 960) {
                    if (!flexibleWidth) {
                        $(el).css('width', `calc(${columnWidth}% - 30px)`);
                    }
                    /** Set medium margin between the rows */
                    if (i >= columns) {
                        $(el).addClass('mt-m');
                    } else if (i < columns) {
                        $(el).removeClass('mt-m');
                    }
                }
                if ($(el).hasClass('cmp-formteaser')) {
                    $(el).find('> div').unbind('click').click((evt) => {
                        if (evt.target.tagName !== 'A') {
                            const value = $(evt.currentTarget).data('value');
                            const hiddenInput = $(el).closest('.cmp-teasercontainer').find('input[type="hidden"]');
                            if (hiddenInput.length > 0) {
                                hiddenInput.val(value);
                            }
                            if ($(evt.currentTarget).closest('.cmp-step-by-step').length > 0) {
                                const nextLink = $(evt.currentTarget).closest('.cmp-step-by-step').find('.cmp-step-by-step__nav-container > a.cmp-step-by-step__next');
                                if ($(nextLink).length > 0) {
                                    $(nextLink).trigger('click', true);
                                }
                            }
                        }
                    });
                }
            });

            $(element).css('visibility', 'visible');
        });
    }

    /**
     * Initializes the functionality if the user clicks on a filter button
     * @param elements teaser container elements
     */
    initializeFilterFunctionality(elements) {
        const self = this;
        elements.each((index, element) => {
            const filterButtons = $(element).find('button[data-tag-id]');
            filterButtons.each((i, button) => {
                // initializes the click on the filter button
                $(button).customOff('click');
                $(button).click(function (e) {
                    const carousel = $(element).find('.owl-carousel');
                    carousel.trigger('destroy.owl.carousel');
                    carousel.removeClass('owl-carousel owl-theme');

                    const clickedButton = $(e.currentTarget);
                    const filterActive = clickedButton.hasClass('active');
                    const tagId = clickedButton.data('tagId');
                    // if the filter was active, remove it from the active list and deactivate the filter
                    if (filterActive) {
                        const id = self.activeFilters.indexOf(tagId);
                        self.activeFilters.splice(id, 1);
                        clickedButton.removeClass('active');
                        // if the filter was inactive, add it to the active list and activate the filter
                    } else {
                        self.activeFilters.push(tagId);
                        clickedButton.addClass('active');
                    }
                    // dependent from the filter list, show and hide teasers
                    if (self.activeFilters.length === 0) {
                        self.showOrHideTeasers(clickedButton);
                    } else {
                        self.showOrHideTeasers(clickedButton, true);
                    }
                    // the view has to be reinitialized because of maybe hidden teaser
                    self.process(clickedButton.closest('.cmp-teasercontainer'));
                });
            });

            // mobile version of the filter
            const showFilterButton = $('.cmp-teasercontainer__filter').find('button.cmp-button__btn');
            $(showFilterButton).customOff('click');
            showFilterButton.click(function (e) {
                $(e.currentTarget).hide();
                $(e.currentTarget).closest('.cmp-teasercontainer__filter').find('ul').show();
            });
        });
    }

    /**
     * Shows or hides the teasers dependent from the current filter state
     * @param clickedButton the currently clicked button
     * @param filter true if any filter is chosen
     */
    showOrHideTeasers(clickedButton, filter) {
        const self = this;
        const teaserList = clickedButton.closest('.cmp-teasercontainer').find('ul.cmp-teasercontainer__unordered-list');
        // first hide the whole teaser list to prevent flickering
        teaserList.hide();
        // we have to save the whole inner html of the teaser list because we have to physically remove the teasers
        // which are filtered out because otherwise the carousel initialization for mobile doesn't work for it
        if (self.teaserHtml !== '') {
            // first reset
            teaserList.html(self.teaserHtml);
        } else {
            self.teaserHtml = teaserList[0].innerHTML;
        }
        const teasers = clickedButton.closest('.cmp-teasercontainer').find('.cmp-teaser > div');
        teasers.each((k, teaser) => {
            const tags = $(teaser).data('cqTags') ? $(teaser).data('cqTags').split(',') : [];
            // remove all teasers which are filtered out
            if (filter && !self.hasOneOrMoreFilterTag(tags)) {
                $(teaser).parent().remove();
            }
        });
        // now we can show the whole teaser list
        teaserList.show();
    }

    /**
     * Checks if the tags of the teaser have one of the filter tags which are currently active
     * @param teaserTags the tags of the teaser
     * @returns {boolean} true, if the teaser has one tag
     */
    hasOneOrMoreFilterTag(teaserTags) {
        const self = this;
        let result = false;
        for (let i = 0; i < self.activeFilters.length && !result; i += 1) {
            for (let j = 0; j < teaserTags.length && !result; j += 1) {
                if (self.activeFilters[i] === teaserTags[j]) {
                    result = true;
                }
            }
        }
        return result;
    }

    getVisibleTeasers(li) {
        const teasers = [];
        li.each((i, el) => {
            if ($(el).is(':visible')) {
                teasers.push(el);
            }
        });
        return teasers;
    }
}

export default TeaserContainer;
